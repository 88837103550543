import RootContainer from "@/components/RootContainer/RootContainer"
import UploadCADFile from "./components/UploadCADFile/UploadCADFile"
import "./SpecificationPage.scss"
import Toolbar from "./components/Toolbar/Toolbar"
import { useEffect, useMemo, useState } from "react"
import EditSpecificationDrawer from "./components/EditSpecificationDrawer/EditSpecificationDrawer"
import { useNavigate, useParams } from "react-router-dom"
import {
  cleanState,
  fileToProductDelete,
  IProductDrawer,
  IQuoteStore,
  setEditProduct,
  setIsUploading,
  setNewQuoteUploaded
} from "@/store/quote"
import { useDispatch, useSelector } from "react-redux"
import ProductItem from "./components/ProductItem/ProductItem"
import { useAppLoading, useLoading } from "@/hooks/useLoading"
import {
  ILocalFile,
  LineItem,
  Product,
  useCreatePriceStartupCostMutation,
  useDeletePurchaseMutation,
  useLazyGetQuoteDetailQuery,
  useLazyGetQuoteQuery
} from "@/services/apiDigifabster/quote"
import {
  useCheckoutFlow,
  useCheckWallThickness,
  useEditSpecification,
  useSuitableMaterials
} from "@/hooks"
import { RootState } from "@/store"
import { Drawer } from "@/components"
import { EModelAnalysisStatus, IProductStore } from "@/store/product"
import { EOrderStatus } from "@/enums/quotesList.enum"
import ModalManualRequest from "./components/ModalManualRequest/ModalManualRequest"
import { ConfigProvider, UploadFile } from "antd"
import { useLazyTechnologyListQuery } from "@/services/apiDigifabster/technology"
import { useUploadModels } from "@/hooks/useUploadModels"
import * as toast from "@/utils/Toast"
import { getLatestDeliveryDate } from "@/utils/timeFormatHelper"
import TopToolbar from "./components/TopToolbar/TopToolbar"
import { evaluateMatches, intersectionMaterials } from "@/utils/arrayHelper"
import TopOrderToolbar from "./components/TopOrderToolbar/TopOrderToolbar"
import { SkeletonProductItem } from "@/components/Skeleton/SkeletonProductItem/SkeletonProductItem"
import { IUserStore } from "@/store/user"
import { IDeliveryTaxRes } from "@/services/apiDigifabster/order"
import {
  IBillingFormValues,
  ICheckoutForm,
  IDeliveryForm
} from "@/interfaces/checkout.interface"
import { EPaymentMethod } from "@/enums/checkout.enum"
import { useLazyGetMeQuery } from "@/services/apiDigifabster/user"

export default function SpecificationPage(): JSX.Element {
  const [selectedItems, setSelectedItems] = useState<LineItem[]>([])
  const [openEditDrawer, setOpenEditDrawer] = useState(false)
  const { openLoading, closeLoading } = useLoading()
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false)
  const [getQuote, { isError, isFetching }] = useLazyGetQuoteQuery()
  const [fileList, setFileList] = useState<UploadFile[]>([])
  const [manualFile, setManualFile] = useState<ILocalFile[]>([])
  const [isLoadingDelete, setIsLoadingDelete] = useState(false)
  const [isLoadingUpdateProduct, setIsLoadingUpdateProduct] = useState<
    Record<number, boolean>
  >({})

  const [getOrderDetail] = useLazyGetQuoteDetailQuery()
  const [getTechnologies, { isFetching: getTechnologiesLoading }] =
    useLazyTechnologyListQuery()
  const { getMaterialConfig } = useEditSpecification()
  const {
    currentQuote,
    selectedProduct,
    suitableMaterial,
    quoteDetail,
    newQuoteUploaded,
    modelsAnalysis
  } = useSelector<RootState, IQuoteStore & IProductStore>((s) => ({
    ...s.quote,
    ...s.product
  }))
  const { technologies } = useSelector<RootState, IProductStore>(
    (s) => s.product
  )
  const { userInfoNew } = useSelector<RootState, IUserStore>((s) => s.user)
  let { quoteId } = useParams()
  const dispatch = useDispatch()

  const { uploadModels } = useUploadModels()
  const [getMe, { isFetching: isLoadingMe }] = useLazyGetMeQuery()

  const [deletePurchase] = useDeletePurchaseMutation()

  const navigate = useNavigate()
  const { sumbitQuote, mapDeliveryTaxRes, isLoadingSubmitQuote } =
    useCheckoutFlow()

  useAppLoading([
    isFetching,
    getTechnologiesLoading,
    isLoadingSubmitQuote,
    isLoadingMe
  ])

  const { isProcessingId: isSuitableProcessingIds } = useSuitableMaterials()
  const { isWallThickNessError, isProcessing } = useCheckWallThickness()

  useEffect(() => {
    if (technologies.length) return

    getTechnologies({ page: 1 })
  }, [technologies])

  useEffect(() => {
    dispatch(cleanState())
    if (quoteId) {
      getQuote({ quoteId })
    }
  }, [quoteId, getQuote])

  useEffect(() => {
    if (isError) {
      return navigate(`/not-found`)
    }
  }, [isError])

  useEffect(() => {
    if (!currentQuote) return

    if (currentQuote.products && currentQuote.products.length > 0) {
      getOrderDetailApi(String(currentQuote?.id))
    }
  }, [currentQuote])

  useEffect(() => {
    if (!currentQuote?.id || !newQuoteUploaded.length || !technologies.length)
      return

    const uploadedFiles = newQuoteUploaded.filter(
      (f) => f.response?.orderId === currentQuote?.id
    )
    if (!uploadedFiles.length) return

    setFileList(uploadedFiles)
    dispatch(setNewQuoteUploaded([]))
    // TODO: handle upload
    dispatch(setIsUploading(true))
    uploadModels(uploadedFiles, "", {
      orderId: currentQuote.id,
      uploadJobId: currentQuote.upload_job,
      onlyPurchase: true,
      uploadInterupted(failedFile) {
        setFileList((pre) => {
          return pre.map<UploadFile>(
            (e) => failedFile.find((f) => f.uid === e.uid) || e
          )
        })
      }
    })
      .then(({ error, files: uploadedFiles }) => {
        if (error) {
          toast.showError(error)
        }
        setFileList((pre) => {
          return pre
            .map<UploadFile>((e) => uploadedFiles[e.uid] || e)
            .filter((e) => e.status !== "done")
        })
      })
      .finally(() => {
        dispatch(setIsUploading(false))
      })
  }, [newQuoteUploaded, currentQuote, technologies])

  const getOrderDetailApi = async (quoteId: string) => {
    const { data } = await getOrderDetail({ quoteId })
    const checkNullThumb = data?.line_items?.some(
      (item) => item.product?.thumb_status === "in_progress"
    )

    if (checkNullThumb) {
      return new Promise((res, rej) => {
        setTimeout(() => {
          getOrderDetailApi(quoteId).then(res).catch(rej)
        }, 1000)
      })
    }
  }

  const suitMaterialsMultiple = useMemo(() => {
    return intersectionMaterials(
      suitableMaterial,
      selectedItems?.map(
        (item) => item?.product?.parent_model || item?.product?.id
      ) || []
    ).filter((e) => e.materials.length > 0)
  }, [selectedItems, suitableMaterial])

  const handleSelectItem = (item: LineItem, selected: boolean) => {
    if (selected) {
      setSelectedItems((prevSelectedItems) => {
        const updatedSelection = [...prevSelectedItems, item]
        return updatedSelection
      })
    } else {
      setSelectedItems((prevSelectedItems) =>
        prevSelectedItems.filter((selectedItem) => selectedItem.id !== item.id)
      )
    }
  }

  const handleCheckout = async () => {
    navigate(`/new-quote/checkout/${quoteId}`, { replace: true })
  }

  const handleSubmitReview = async () => {
    try {
      const payload: Partial<Omit<ICheckoutForm, "delivery_address">> & {
        delivery_address: IDeliveryForm
      } = {
        delivery_address: {
          name: userInfoNew?.name,
          surname: userInfoNew?.surname,
          phoneNumber: userInfoNew?.phone_number,
          email: userInfoNew?.email,
          city: "",
          country: { iso2: "", name: "", id: "" },
          state: { iso2: "", name: "", id: "" },
          company: "",
          addressLine1: "",
          addressLine2: "",
          zipCode: ""
        }
      }

      if (!currentQuote) return

      const currentStatus = isRequiresReview
        ? EOrderStatus.WAITING_FOR_REVIEW
        : (currentQuote?.status as EOrderStatus) || EOrderStatus.INITIAL

      const deliveryTaxRes: IDeliveryTaxRes[] = mapDeliveryTaxRes({
        delivery_address: payload.delivery_address,
        shipping_method: "",
        po_number: "",
        attached_po: {} as UploadFile,
        billing: {} as IBillingFormValues
      })

      const paymentMethod: EPaymentMethod = EPaymentMethod.CREDIT_CARD

      const resSubmitOrder = await sumbitQuote(
        payload as ICheckoutForm,
        currentStatus,
        currentQuote.id || 0,
        paymentMethod,
        deliveryTaxRes
      )

      const errorMsg = (resSubmitOrder.error as any)?.data?.message || ""
      if (resSubmitOrder.error || !resSubmitOrder.data)
        throw new Error(errorMsg)
      const invoice_id = Number(resSubmitOrder.data.invoiceId)
      const invoiceHash = resSubmitOrder.data.invoiceHash
      await getMe()
      navigate(`/quotes/invoices/${invoice_id}/${invoiceHash}`)
    } catch (err: any) {
      console.log("err", err)
      const message = err
      toast.showError(message.message || "Failed to submit for review")
    }
  }

  const handleEditItem = (id: number) => {
    if (selectedItems.length)
      return handleEditItems(selectedItems.map((e) => e.id))
    const product = currentQuote?.products.find((e) => e.id === id)
    if (!product) return
    const ids = [{ id: id, model_id: product.model_id }]
    const mapProduct = { ...product, ids } as IProductDrawer
    dispatch(setEditProduct(mapProduct))
    setOpenEditDrawer(true)
  }

  const handleEditItems = (id: number[]) => {
    const product = currentQuote?.products.find(
      (e) => e.id === id[0]
    ) as Product
    const { material } = getMaterialConfig(
      technologies,
      product,
      suitMaterialsMultiple,
      true
    )
    if (!product) return
    const ids = selectedItems.map((e) => {
      return { id: e.id, model_id: e.product.id }
    })
    const mapProduct = {
      ...product,
      material_id: material,
      ids
    } as IProductDrawer
    dispatch(setEditProduct(mapProduct))
    setOpenEditDrawer(true)
  }

  const handleCloseEditModal = () => {
    setOpenEditDrawer(false)
  }

  const handleSaveQuote = () => {
    openLoading()

    navigate("/new-quote/save-quote-success")
    setTimeout(() => {
      closeLoading()
    }, 2000)
  }

  const openModalManualRequest = () => {
    setIsOpenModal(true)
  }

  const closeModalManualRequest = () => {
    setIsOpenModal(false)
  }

  const setIsLoadingItem = (itemIds: number[], isLoading: boolean) => {
    setIsLoadingUpdateProduct((prev) => {
      const loadingProduct = { ...prev }
      itemIds?.forEach((id) => {
        loadingProduct[id] = isLoading
      })
      return loadingProduct
    })
  }

  const handleDeleteItem = async () => {
    const idsToDelete = selectedItems.map((item) => item.id)

    const idsToRemove: number[] = [] // Collect the IDs of successfully deleted items

    setIsLoadingItem(idsToDelete, true)

    for (const item of selectedItems) {
      try {
        await deletePurchase({
          orderId: currentQuote?.id || 0,
          purchaseId: item.id
        })
        idsToRemove.push(item.id)
      } catch (_) {}
    }
    // Remove the successfully deleted items from the selected items
    setSelectedItems((prevSelectedItems) =>
      prevSelectedItems.filter(
        (selectedItem) => !idsToRemove.includes(selectedItem.id)
      )
    )

    setIsLoadingItem(idsToDelete, false)
  }

  const isRequiresReview = useMemo(() => {
    return (
      currentQuote?.available_order_initial_statuses?.includes(
        EOrderStatus.WAITING_FOR_REVIEW
      ) ||
      quoteDetail?.line_items?.some(
        (item) => item?.status === EOrderStatus.WAITING_FOR_REVIEW
      ) ||
      quoteDetail?.line_items?.some(
        (item) =>
          modelsAnalysis[item?.product?.id]?.status ===
          EModelAnalysisStatus.ERROR
      )
    )
  }, [currentQuote, quoteDetail, modelsAnalysis])

  const isMultiple = useMemo(
    () => Boolean(selectedItems.length > 1),
    [selectedItems]
  )

  const displayParts = (length: number) => {
    if (length === 1 || length === 0) return `${length} Part`
    return `${length} Parts`
  }

  if (!currentQuote || isFetching || getTechnologiesLoading) return <></>

  // edit available when no loading and
  // if loading, the item wasn't selected
  const isEditTable = (item: LineItem) => {
    return (
      !!suitableMaterial[item?.product?.parent_model || item?.product?.id] &&
      (!isLoadingUpdateProduct[item?.id] ||
        (!selectedItems?.some((e) => e.id === item.id) &&
          selectedProduct?.id !== item.id)) &&
      !(
        isSuitableProcessingIds?.includes(item?.product?.parent_model) ||
        isSuitableProcessingIds?.includes(item?.product?.id)
      )
    )
  }

  return (
    <RootContainer
      onBack={() => navigate("/new-quote")}
      headerText="Order Configuration"
      subHeaderText={`${currentQuote?.id ? `Q-${currentQuote?.id}` : ""}`}
      customRightElement={
        <TopOrderToolbar
          data={currentQuote?.products || []}
          onCheckOut={handleCheckout}
          onSaveQuote={handleSaveQuote}
          onSubmitReview={handleSubmitReview}
          isRequiresReview={isRequiresReview}
          DFMError={isWallThickNessError}
          onSubmitManualRequest={openModalManualRequest}
        />
      }
    >
      <div className="container-spec">
        <TopToolbar
          isEditable={
            (evaluateMatches(quoteDetail?.line_items, selectedItems).editable &&
              quoteDetail?.line_items?.some((item) => isEditTable(item))) ||
            false
          }
          isDeletable={
            evaluateMatches(quoteDetail?.line_items, selectedItems).deletable
          }
          onSubmitManualRequest={openModalManualRequest}
          openDrawer={() => handleEditItems(selectedItems?.map((e) => e?.id))}
          onDelete={handleDeleteItem}
          isMultiple={isMultiple}
          numberPart={selectedItems?.length}
        />
        <Toolbar
          isSelectedAll={
            evaluateMatches(quoteDetail?.line_items, selectedItems).allMatch
          }
          deselectAll={() => setSelectedItems([])}
          isSelectedNone={selectedItems.length === 0}
          selectAll={() => setSelectedItems(quoteDetail?.line_items || [])}
        />
        {(quoteDetail?.line_items || []).map((item) =>
          item?.local_file ? (
            <SkeletonProductItem
              key={item.local_file.id}
              file={item.local_file as any}
              onOpenManualRequest={() => {
                openModalManualRequest()
                setManualFile([item.local_file as any])
              }}
              onDelete={() =>
                dispatch(fileToProductDelete(String(item.local_file?.id)))
              }
            />
          ) : (
            <ProductItem
              key={item.id}
              item={item}
              isSelected={selectedItems?.some(
                (selectedItem) => selectedItem.id === item.id
              )}
              onSelect={(selected) => handleSelectItem(item, selected)}
              onEditItem={handleEditItem}
              isEditable={isEditTable(item)}
              isLoadingUpdateProduct={isLoadingUpdateProduct}
              isProcessing={isProcessing}
              openDrawer={handleEditItem}
              deliveryDays={getLatestDeliveryDate(quoteDetail?.line_items)}
            />
          )
        )}
        <div className="upload-box">
          <UploadCADFile fileList={fileList} setFileList={setFileList} />
        </div>
        {/* <OrderToolbar
          data={currentQuote?.products || []}
          onCheckOut={handleCheckout}
          onSaveQuote={handleSaveQuote}
          isRequiresReview={
            currentQuote?.available_order_initial_statuses?.[0] ===
            EOrderStatus.WAITING_FOR_REVIEW
          }
          DFMError={isWallThickNessError || isProcessing}
          onSubmitManualRequest={openModalManualRequest}
        /> */}
        {
          <ConfigProvider
            theme={{
              token: {
                fontFamily: '"Open Sans", sans-serif'
              }
            }}
          >
            <Drawer
              className="edit-specification-drawer"
              width={480}
              open={openEditDrawer}
              title={
                isMultiple
                  ? `Edit Configuration (${displayParts(selectedItems.length)})`
                  : "Edit Configuration"
              }
              onClose={handleCloseEditModal}
            >
              {!isMultiple && (
                <div className="edit-specification-drawer-infor">
                  <div className="edit-specification-drawer-infor-wrapper">
                    <img
                      src={selectedProduct?.model_thumbnail}
                      className="edit-specification-drawer-infor-img"
                      alt=""
                    />
                    <div className="edit-specification-drawer-infor-title">
                      <h3 className="">
                        {String(selectedProduct?.model_title)}
                      </h3>
                      <h5 className="">Product Description</h5>
                    </div>
                  </div>
                </div>
              )}
              <EditSpecificationDrawer
                onUpdateDone={handleCloseEditModal}
                setIsLoadingUpdateProduct={setIsLoadingItem}
                openEditDrawer={openEditDrawer}
                isMultiple={isMultiple}
                suitMaterialsMultiple={suitMaterialsMultiple}
                setSelectedItems={setSelectedItems}
                setIsLoadingDelete={setIsLoadingDelete}
              />
            </Drawer>
          </ConfigProvider>
        }
        <ModalManualRequest
          openModal={isOpenModal}
          closeModal={closeModalManualRequest}
          currentQuote={currentQuote}
          prefillFile={manualFile}
        />
      </div>
    </RootContainer>
  )
}
